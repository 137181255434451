import { useTranslation } from "react-i18next";
import { useDynamicResolution } from "../../hooks";
import { Main } from "../Main/Main";
import { useState } from "react";
import { Input } from "../Core/Inputs";
import { getQrBalance, getNFCBalance } from "../../services/balance";
import Modal from "../Core/modal";
import Loading from "../Core/Loading";
import { BarcodeScanner } from "../Core/QrScanner";
import { QrCodeIcon } from "@heroicons/react/24/outline";
import { CodeHint } from "../Refunds/components/codeHint";

const Balance = () => {
  const { t } = useTranslation();
  const isDesktop: boolean = useDynamicResolution();

  const [userCode, setUserCode] = useState<string>("");
  const [balance, setBalance] = useState<number>(0);
  const [userCodeError, setUserCodeError] = useState<string>("");
  const [balanceError, setBalanceError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [showScanner, setShowScanner] = useState<boolean>(false);
  const handleUserCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserCode(e.target.value);
  };

  const handleGetQRBalance = async (code?: string) => {
    try {
      setIsLoading(true);
      setIsModalOpen(true);
      const response = await getQrBalance(code || userCode);
      if (response !== null) {
        setBalance(response.data.current_balance);
      } else {
        setBalanceError(t("balance.errors.codeNotFound"));
      }
    } catch (error) {
      setBalanceError(t("balance.errors.codeNotFound"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetNFCBalance = async (code?: string) => {
    try {
      setIsLoading(true);
      setIsModalOpen(true);
      const response = await getNFCBalance(code || userCode);
      console.log(response);
      if (response !== null) {
        setBalance(response.data.balance);
      } else {
        setBalanceError(t("balance.errors.codeNotFound"));
      }
    } catch (error) {
      setBalanceError(t("balance.errors.codeNotFound"));
    } finally {
      setIsLoading(false);
    }
  };

  const validateForm = (code?: string) => {
    setUserCodeError("");
    setBalanceError("");
    const codeToValidate = code || userCode;

    if (codeToValidate.length === 0) {
      setUserCodeError(t("balance.errors.invalidUserCode"));
      return;
    }

    if (codeToValidate.includes("tick") || codeToValidate.length === 23) {
      handleGetQRBalance(codeToValidate);
      return;
    }

    if (codeToValidate.length <= 17 && /^[0-9A-Fa-f]+$/.test(codeToValidate)) {
      handleGetNFCBalance(codeToValidate);
      return;
    }

    setUserCodeError(t("balance.errors.invalidUserCode"));
  };

  const handleScan = (result: string) => {
    setUserCode(result);
    setShowScanner(false);
    validateForm(result);
  };

  return (
    <Main isDesktop={isDesktop} t={t} hasFooter={isDesktop}>
      <section className="bg-white pt-24 px-[5%] sm:px-[3%] md:pt-40  md:pb-40">
        <BarcodeScanner
          isOpen={showScanner}
          onClose={() => setShowScanner(false)}
          onScan={handleScan}
        />
        <div className="w-full max-w-[80rem] mx-auto space-y-6 md:space-y-12 ">
          <h1 className="text-2xl font-bold">{t("balance.title")}</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 items-center ">
            <div className="flex items-center gap-2">
              <Input
                placeholder={t("balance.userCodePlaceholder")}
                value={userCode}
                onChange={handleUserCodeChange}
                type="textHexa"
                label={t("balance.userCode")}
                isRequired
                limit={42}
                error={userCodeError}
                hint={<CodeHint />}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    validateForm();
                  }
                }}
              />
              <button
                onClick={() => setShowScanner(true)}
                className="h-10 w-10 flex items-center justify-center hover:bg-gray-100 rounded-full"
              >
                <QrCodeIcon className="w-6 h-6 text-primary" />
              </button>
            </div>
            <button
              onClick={() => validateForm()}
              disabled={isLoading}
              className="bg-primary text-white px-4 py-2 rounded-md font-bold w-full md:w-fit min-w-[100px] h-10 hover:opacity-90 transition-opacity"
            >
              {t("balance.getBalanceButton")}
            </button>
          </div>
        </div>
        <Modal
          title={t("balance.modal.title")}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <div className="flex justify-center items-center h-full">
            {isLoading ? (
              <Loading />
            ) : balanceError.length > 0 ? (
              <span className="text-xl font-semibold text-gray-800">
                {balanceError}
              </span>
            ) : (
              <span className="text-xl font-semibold text-gray-800">
                {t("balance.balanceResult")}:
                <br />
                <span className="text-primary ">
                  $
                  {balance.toLocaleString("es-MX", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  MXN
                </span>
              </span>
            )}
          </div>
        </Modal>
      </section>
    </Main>
  );
};

export default Balance;
