/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Input } from "../../Core/Inputs";
import { RefundBody } from "../interfaces";
import { validateCLABE, validateUserCode } from "../../../utils";

import { CodeHint } from "./codeHint";
import { TFunction } from "i18next";
import { QrCodeIcon } from "@heroicons/react/24/outline";
import { BarcodeScanner } from "../../Core/QrScanner";

const initialRefundBody: RefundBody = {
  name: "",
  lastName: "",
  phone: "",
  userCode: "",
  clabe: "",
};

interface RefundFormProps {
  refundBody: RefundBody;
  handleInputChange: (
    field: keyof RefundBody
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
  t: TFunction<"translate", undefined>;
}

export const RefundForm = ({
  refundBody,
  handleInputChange,
  handleSubmit,
  t,
}: RefundFormProps) => {
  const [refundErrors, setRefundErrors] = useState<RefundBody>({
    ...initialRefundBody,
  });
  const [showScanner, setShowScanner] = useState<boolean>(false);

  const ValidateForm = () => {
    setRefundErrors({ ...initialRefundBody });
    const isValidForm = validateRefundBody(refundBody);

    if (!isValidForm) {
      return;
    }
    handleSubmit();
  };

  const validateRefundBody = (body: RefundBody): boolean => {
    let isValidForm = true;
    if (!body.name) {
      setRefundErrors((prev) => ({
        ...prev,
        name: t("refunds.errors.nameRequired"),
      }));
      isValidForm = false;
    }
    if (!body.lastName) {
      setRefundErrors((prev) => ({
        ...prev,
        lastName: t("refunds.errors.lastNameRequired"),
      }));
      isValidForm = false;
    }
    if (!body.userCode) {
      setRefundErrors((prev) => ({
        ...prev,
        userCode: t("refunds.errors.userCodeRequired"),
      }));
      isValidForm = false;
    }

    if (!validateUserCode(body.userCode)) {
      setRefundErrors((prev) => ({
        ...prev,
        userCode: t("refunds.errors.invalidUserCode"),
      }));
      isValidForm = false;
    }
    if (!validateCLABE(body.clabe)) {
      setRefundErrors((prev) => ({
        ...prev,
        clabe: t("refunds.errors.invalidClabe"),
      }));
      isValidForm = false;
    }
    return isValidForm;
  };

  useEffect(() => {
    if (!validateCLABE(refundBody.clabe)) {
      setRefundErrors((prev) => ({
        ...prev,
        clabe: t("refunds.errors.invalidClabe"),
      }));
    } else {
      setRefundErrors((prev) => ({
        ...prev,
        clabe: "",
      }));
    }
  }, [refundBody.clabe]);

  const handleScan = (result: string) => {
    handleInputChange("userCode")({
      target: { value: result },
    } as React.ChangeEvent<HTMLInputElement>);
    setShowScanner(false);
  };

  return (
    <div className="w-full max-w-[80rem] mx-auto space-y-6 md:space-y-12 ">
      <h1 className="text-2xl font-bold">{t("refunds.title")}</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 items-center ">
        <BarcodeScanner
          isOpen={showScanner}
          onClose={() => setShowScanner(false)}
          onScan={handleScan}
        />
        <Input
          placeholder={t("refunds.name")}
          type="textNoNumbers"
          value={refundBody.name}
          onChange={handleInputChange("name")}
          label={t("refunds.name")}
          isRequired
          error={refundErrors.name}
        />
        <Input
          placeholder={t("refunds.lastName")}
          type="textNoNumbers"
          value={refundBody.lastName}
          onChange={handleInputChange("lastName")}
          label={t("refunds.lastName")}
          isRequired
          error={refundErrors.lastName}
        />
        <div className="flex items-center gap-2">
          <Input
            placeholder={t("refunds.userCodePlaceholder")}
            type="textHexa"
            value={refundBody.userCode}
            onChange={handleInputChange("userCode")}
            label={t("refunds.userCode")}
            isRequired
            limit={42}
            error={refundErrors.userCode}
            hint={<CodeHint />}
          />
          <button
            onClick={() => setShowScanner(true)}
            className="h-10 w-10 flex items-center justify-center hover:bg-gray-100 rounded-full"
          >
            <QrCodeIcon className="w-6 h-6 text-primary" />
          </button>
        </div>

        <Input
          label={t("refunds.clabe")}
          placeholder={t("refunds.clabe")}
          type="number"
          value={refundBody.clabe}
          onChange={handleInputChange("clabe")}
          isRequired
          limit={18}
          error={refundErrors.clabe}
          leftAddon={refundErrors.clabe ? "" : "✓"}
        />
      </div>
      <div className="flex justify-start items-center w-full">
        <button
          onClick={ValidateForm}
          className="bg-primary text-white px-6 py-3 rounded-md font-bold w-full sm:w-auto min-w-[120px] h-12 hover:opacity-90 transition-opacity"
        >
          {t("refunds.submitButton")}
        </button>
      </div>
    </div>
  );
};
